import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const InvoiceChargesApiService = {
  async getInvoiceCharges(
    {
      accountId,
      orderId,
      startDate,
      endDate,
      notInvoiced,
      notReleased,
      deleted,
      pageSize = 10,
      pageNum = 1,
    },
  ) {
    try {
      const { data } = await HTTP.get('charges', {
        params: {
          accountId,
          orderId,
          startDate,
          endDate,
          notInvoiced,
          notReleased,
          deleted,
          pageSize,
          pageNum,
        },
      });

      return data.items || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async releaseCharge({ chargeId, dateReleased, comment }) {
    try {
      return await HTTP.post(`charges/${chargeId}/release`, { dateReleased, comment });
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};
