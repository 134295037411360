<template>
  <v-menu
    :close-on-content-click="false"
    open-on-hover
    nudge-top="150"
    left
  >
    <template #activator="{ on }">
      <v-btn
        icon
        small
        v-on="on"
      >
        <v-icon>
          insert_chart_outlined
        </v-icon>
      </v-btn>
    </template>

    <v-list class="px-4 py-4">
      <v-layout column>
        <h3>
          Metadata
        </h3>

        <v-layout class="mt-1">
          <strong class="mr-2">
            Country:
          </strong>

          {{ metadata.country || '-' }}
        </v-layout>

        <v-layout class="mt-2">
          <strong class="mr-2">
            State:
          </strong>

          {{ metadata.state || '-' }}
        </v-layout>

        <v-layout class="mt-2">
          <strong class="mr-2">
            ZIP:
          </strong>

          {{ metadata.zip || '-' }}
        </v-layout>

        <v-layout class="mt-2">
          <strong class="mr-2">
            Tax Name:
          </strong>

          {{ metadata.taxName || '-' }}
        </v-layout>

        <v-layout class="mt-2">
          <strong class="mr-2">
            Tracking Number:
          </strong>

          {{ metadata.trackingNumber || '-' }}
        </v-layout>

        <v-layout class="mt-2">
          <strong class="mr-2">
            Original Currency Code:
          </strong>

          {{ metadata.originalCurrencyCode || '-' }}
        </v-layout>

        <v-layout class="mt-2">
          <strong class="mr-2">
            Currency Exchange Rate:
          </strong>

          {{ metadata.currencyExchangeRate || '-' }}
        </v-layout>

        <v-layout class="mt-2">
          <strong class="mr-2">
            MSBC Order:
          </strong>

          {{ item.msbcOrderNumber || '-' }}
        </v-layout>

        <v-layout class="mt-2">
          <strong class="mr-2">
            MSBC Invoice:
          </strong>

          {{ item.msbcInvoiceNumber || '-' }}
        </v-layout>

        <v-layout class="mt-2">
          <strong class="mr-2">
            IR Invoice:
          </strong>

          {{ item.invoiceNumber || '-' }}
        </v-layout>

        <v-layout class="mt-2 mb-1">
          <strong class="mr-2">
            Comment:
          </strong>

          {{ metadata.comment || '-' }}
        </v-layout>
      </v-layout>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'IrInvoiceChargeMetadata',

  props: {
    metadata: {
      type: Object,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
