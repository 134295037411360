<template>
  <v-layout>
    <v-menu
      close-delay="100"
      nudge-left="30"
      open-on-hover
      left
    >
      <template #activator="{ on }">
        <v-btn
          icon
          small
          class="ml-2"
          v-on="on"
        >
          <v-icon>
            more_vert
          </v-icon>
        </v-btn>
      </template>

      <v-layout column>
        <v-btn
          small
          @click="releaseDialog = true"
        >
          Release
        </v-btn>
      </v-layout>
    </v-menu>

    <vbt-dialog
      v-model="releaseDialog"
      :disable-submit-btn="!releaseComment"
      submit-btn-name="Release"
      title="Release Charge"
      width="350px"
      @submit="releaseCharge"
    >
      <vbt-date-picker
        v-model="releaseDate"
        :min="minReleaseDate"
        label="Release Date"
      />

      <v-textarea
        v-model.trim="releaseComment"
        label="Comment"
        rows="4"
      />
    </vbt-dialog>
  </v-layout>
</template>

<script>
import { wrapToLoadingFn, formatDisplayDate } from '@helpers';

import { InvoiceChargesApiService } from '../_services';

const getTodayDate = () => formatDisplayDate((new Date()).toDateString(), { dateFormat: 'yyyy-MM-dd' });

export default {
  name: 'IrInvoiceChargeActions',

  props: {
    chargeId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      releaseDialog: false,
      releaseDate: getTodayDate(),
      releaseComment: '',
      minReleaseDate: getTodayDate(),
    };
  },

  methods: {
    releaseCharge() {
      this.$VBlackerTheme.alert.warning({ text: 'Release charge?' }, () => this.wrapToLoadingFn({
        req: InvoiceChargesApiService.releaseCharge.bind(null, {
          dateReleased: formatDisplayDate(this.releaseDate, { dateFormat: 'yyyy-MM-dd' }),
          comment: this.releaseComment,
          chargeId: this.chargeId,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Charge successfully released');
          this.resetReleaseChargeForm();
          this.$emit('refresh');
          this.releaseDialog = false;
        },
      }));
    },

    resetReleaseChargeForm() {
      this.minReleaseDate = getTodayDate();
      this.releaseDate = getTodayDate();
      this.releaseComment = '';
    },

    wrapToLoadingFn,
  },
};
</script>
