<template>
  <vbt-search
    v-model="searchData"
    :items="searchFieldsConfig"
    @reset="onResetSearch"
    @search="onSearch"
  >
    <template #title-right>
      <v-layout align-center>
        <v-spacer />

        <span
          class="vbt-margin-r-10"
          style="font-size: 17px;"
        >
          All accounts
        </span>

        <v-blacker-switch
          color="info"
          class="custom-search-toggle"
          @change="getAccounts"
        />
      </v-layout>
    </template>
  </vbt-search>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { useDataLoading, useSearch } from '@mixins/factories';

const dataLoadingOptions = { getterName: 'handleAfterSearch' };

const searchOptions = {
  initialSearchDataStates: ['orderId', 'accountId', 'dates', 'notInvoiced', 'notReleased', 'deleted'],
  customTypes: {
    notInvoiced: 'booleanString',
    notReleased: 'booleanString',
    deleted: 'booleanString',
    accountId: 'number',
    dates: 'array',
  },
};

export default {
  name: 'IrInvoiceChargesSearch',

  mixins: [
    useDataLoading(dataLoadingOptions),
    useSearch(searchOptions),
  ],

  computed: {
    ...mapGetters('accounts', ['accountsOptions']),

    searchFieldsConfig() {
      return [
        [
          {
            value: 'orderId',
            label: 'Order ID',
            type: 'text',
            flex: 'sm4',
          },
          {
            value: 'accountId',
            items: this.accountsOptions,
            label: 'Account',
            type: 'combobox',
            flex: 'sm4',
          },
          {
            value: 'dates',
            label: 'Date Range',
            type: 'daterange',
            flex: 'sm4',
          },
        ],
        [
          {
            value: 'notInvoiced',
            label: 'Not Invoiced',
            type: 'toggle',
          },
          {
            value: 'notReleased',
            label: 'Not Released',
            type: 'toggle',
          },
          {
            value: 'deleted',
            label: 'Deleted',
            type: 'toggle',
          },
        ],
      ];
    },
  },

  created() {
    this.getAccounts();
  },

  methods: {
    ...mapActions('accounts', {
      getAccounts(dispatch, isAllAccounts = false) {
        dispatch('getAccounts', { activeOnly: !isAllAccounts });
      },
    }),

    handleAfterSearch(params) {
      this.$emit('search', params);
    },
  },
};
</script>
