<template>
  <vbt-table
    :items="items"
    :headers="headers"
    :page-size="pageSize"
    customBody
  >
    <tbody>
      <tr
        v-for="(item, i) in items"
        :key="i"
        :class="{ 'deleted-charge': item.deleted }"
      >
        <td>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                :color="checkIsInvoiced(item) ? 'success' : 'default'"
                v-on="on"
              >
                monetization_on
              </v-icon>
            </template>

            {{ checkIsInvoiced(item) ? 'Already invoiced' : 'Not invoiced' }}
          </v-tooltip>
        </td>

        <td>
          {{ item.id }}
        </td>

        <td>
          {{ accountNameByIdFromAllAccounts[item.accountId] }}
        </td>

        <td v-if="!hidedColumns.includes('orderId')">
          <router-link
            v-if="item.orderId"
            :to="{
              name: 'order',
              params: { id: item.orderId },
            }"
            class="blue--text darken-1"
          >
            {{ item.orderId }}
          </router-link>
          <span v-else>
            -
          </span>
        </td>

        <td>
          {{ item.customerOrderItemId || '-' }}
        </td>

        <td>
          {{ item.chargeCode || '-' }}
        </td>

        <td>
          {{ item.productType || '-' }}
        </td>

        <td>
          {{ formatNumber(item.quantity) || '-' }}
        </td>

        <td>
          <v-layout
            v-if="item.dateCreated && item.dateReleased"
            column
          >
            {{ formatDisplayDate(item.dateCreated) }} /
            <div>
              {{ formatDisplayDate(item.dateReleased) }}
            </div>
          </v-layout>
          <template v-else-if="item.dateCreated">
            {{ formatDisplayDate(item.dateCreated) }}
          </template>
          <template v-else-if="item.dateReleased">
            {{ formatDisplayDate(item.dateReleased) }}
          </template>
        </td>

        <td>
          {{ formatNumber(item.amount) }}
          / {{ formatNumber(item.taxAmount) }}
          / {{ formatNumber(item.amountWithTax) }}
          {{ ` ${item.currencyCode}` }}
        </td>

        <td>
          <v-layout>
            <ir-invoice-charge-metadata
              :metadata="item.metadata"
              :item="item"
            />

            <ir-invoice-charge-actions
              v-if="actions"
              :charge-id="item.id"
              v-on="$listeners"
            />
          </v-layout>
        </td>
      </tr>
    </tbody>
  </vbt-table>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatDisplayDate, formatNumber } from '@helpers';

import IrInvoiceChargeMetadata from './IrInvoiceChargeMetadata.vue';
import IrInvoiceChargeActions from './IrInvoiceChargeActions.vue';

const headers = Object.freeze([
  { text: '', value: 'invoiced', width: '10px' },
  { text: 'Id', value: 'id', width: '10px' },
  { text: 'Account', value: 'accountId' },
  { text: 'Order ID', value: 'orderId' },
  { text: 'Customer Order Item ID', value: 'customerOrderItemId' },
  { text: 'Charge Code', value: 'chargeCode' },
  { text: 'Product Type', value: 'productType' },
  { text: 'Quantity', value: 'quantity' },
  { text: 'Created/Released Date', value: 'dates' },
  { text: 'Amount/Tax/With Tax', value: 'amount' },
  { text: '', value: 'actions' },
]);

export default {
  name: 'IrInvoiceChargesList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    pageSize: {
      type: Number,
      default: 10,
    },

    hidedColumns: {
      type: Array,
      default: () => [],
    },

    actions: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    IrInvoiceChargeMetadata,
    IrInvoiceChargeActions,
  },

  computed: {
    ...mapGetters('accounts', ['accountNameByIdFromAllAccounts']),

    headers() {
      return this.hidedColumns.length
        ? headers.filter(({ value }) => !this.hidedColumns.includes(value))
        : headers;
    },
  },

  methods: {
    checkIsInvoiced({ msbcInvoiceNumber, invoiceNumber }) {
      return Boolean(msbcInvoiceNumber || invoiceNumber);
    },

    formatDisplayDate,
    formatNumber,
  },
};
</script>

<style lang="css" scoped>
.deleted-charge {
  background: #e7e3e345;
}

.deleted-charge td {
  position: relative;
}

.deleted-charge td:before {
  content: " ";
  position: absolute;
  top: 47%;
  left: 0;
  border-bottom: 1px solid #fff;
  width: 100%;
}
</style>
